import { Grid } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MySidebar from "../components/sidebar";
import { Api, ApiLogin } from "../providers";
import { LoadingProvider } from "./service";
import { UserContext } from "./user";
import axios from "axios";
import { BaseURL } from "../api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const { printLayout, setIsLoggedUser, setPrintLayout } = useContext(UserContext);
    const baseURL = BaseURL;
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [currentScreen, setCurrentScreen] = useState(0);
    const getStorage = JSON.parse(localStorage.getItem("user"));
    const getToken = localStorage.getItem("token");

    const setLocalStorageUser = (data) => {
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("token", data.token);
    };

    const setLocalStoragePermissions = (data) => {
        localStorage.setItem("permissoes", JSON.stringify(data));
    };

    const setLocalStorageScreens = (data) => {
        localStorage.setItem("telas", JSON.stringify(data));
    };

    //Usuário
    const setUserData = async (data) => {
        if (data) {
            setUser({
                user: data,
                token: data.token,
            });
            setPrintLayout(false);
            setIsLoggedUser(true);
            try {
                let status1 = false;
                let status2 = false;
                await Promise.all([
                    Api.get("/operador/tela").then((res) => {
                        if (res.status === 200) {
                            status1 = true;
                            setLocalStorageScreens(res.data);
                        } else {
                            toast.error(`Ops! ${res.data.message.error}`, {
                                toastId: "error",
                            });
                        }
                    }),
                    Api.get("/operador/" + data.operador.id).then((res) => {
                        if (res.status === 200) {
                            status2 = true;
                            setLocalStoragePermissions(res.data.permissoes);
                        } else {
                            toast.error(`Ops! ${res.data.message.error}`, {
                                toastId: "error",
                            });
                        }
                    }),
                ]);
                if (status1 && status2) {
                    //Important!
                    window.location.href = "/servico";
                }
            } catch (_) {
                toast.error("Ops! Não foi possivel buscar suas permissões", {
                    toastId: "error",
                });
            } finally {
                setLoading(false);
            }
        }
    };

    const sendToastMessageError = (err) => {
        let message =
            typeof err.response !== "undefined"
                ? err.response.data.message
                : err.message;

        toast.error(`Ops! ${message.error}`, {
            toastId: "error",
        });
    };

    const login = async (cpf, password) => {
        setLoading(true);

        const request = {
            login: cpf,
            senha: password,
            perfil: "OPERADOR",
        };

        await ApiLogin.post("login", request)
            .then(responseLogin => {
                if (responseLogin.status === 200) {
                    setLocalStorageUser(responseLogin.data);
                    const getContent = axios.create({
                        baseURL: `${baseURL}api/`,
                        headers: {
                            Authorization: `Bearer ${responseLogin.data.token}`,
                        },
                    });

                    Promise.all([
                        getContent.get("operador/tela")
                            .then(responseScreen => {
                                if (responseScreen.status === 200) setLocalStorageScreens(responseScreen.data);
                            })
                            .catch((err) => sendToastMessageError(err)),
                        getContent.get("operador/" + responseLogin.data.operador.id)
                            .then(responsePermissions => {
                                if (responsePermissions.status === 200) setLocalStoragePermissions(responsePermissions.data.permissoes);
                            })
                            .catch((err) => sendToastMessageError(err)),
                    ]);

                    setTimeout(() => {
                        // navigate("/servico");
                        window.location.href = "/servico";
                    }, 2000)
                }
            })
            .catch((err) => sendToastMessageError(err))
            .finally(() => setLoading(false));
    };

    const loginDescotinuado = async (cpf, password) => {
        setLoading(true);
        const requisicao = {
            login: cpf,
            senha: password,
            perfil: "OPERADOR",
        };
        try {
            const res = await ApiLogin.post("login", requisicao);
            if (res.status === 200 && res.data) {
                setStorage(res.data);
                setTimeout(() => {
                    setUserData(res.data);
                }, 2000);
            }
        } catch (err) {
            setLoading(false);
            let message =
                typeof err.response !== "undefined"
                    ? err.response.data.message
                    : err.message;

            toast.error(`Ops! ${message.error}`, {
                toastId: "error",
            });
        }
    };

    // Sair do sistema
    const logout = () => {
        //Reset ALL
        localStorage.clear();
        setUser(null);
        setIsLoggedUser(false);
        setLoading(false);

        toast.warn("Você saiu do Sistema", {
            toastId: "warn",
            autoClose: 1000,
        });

        navigate("/login");
    };

    //Atualizar token
    const refreshToken = () => {
        setTimeout(() => {
            if (getToken) {
                // console.log(getToken);
            }
        }, 5000);
    };

    //Verifica se autenticado. Poderá ver se o token é válido
    const isAuthenticated = () => {
        return !!getStorage;
    };

    return (
        // authenticated : true | false
        <AuthContext.Provider
            value={{
                authenticated: isAuthenticated(),
                user,
                login,
                logout,
                refreshToken,
                currentScreen,
                setCurrentScreen,
                loading,
            }}
        >
            <LoadingProvider>
                <Grid container spacing={4}>
                    {/* Imprime layout publico, sem estar logado */}
                    {isAuthenticated() === false ? (
                        <Grid item>{children}</Grid>
                    ) : !!printLayout ? (
                        /*  Layout Private */
                        <Grid item>{children}</Grid>
                    ) : (
                        /* Layout Private */
                        <>
                            <Grid item xs={6} sm={3} md={3}>
                                <MySidebar currentScreen={currentScreen} />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={8}
                                md={8}
                                sx={{
                                    // width: "100%",
                                    // maxWidth: "1056px",
                                    // minHeight: "943px",
                                    margin: "32px 0px 0px 32px",
                                }}
                            >
                                {children}
                            </Grid>
                        </>
                    )}
                </Grid>
            </LoadingProvider>
        </AuthContext.Provider>
    );
};
